<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :permission="permissionList"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot-scope="{row}"
                slot="recentTask">
        <span class="viewDetail" @click="viewDetail(row)">{{row.recentTask}}</span>
      </template>
      <template slot-scope="{row}"
                slot="recentEquipment">
        <span class="viewDetail" @click="handleViewEquipment(row)">{{row.recentEquipment}}</span>
      </template>
      <template slot="menuLeft">
        <el-button type="primary"
                   size="small"
                   icon="el-icon-download"
                   plain
                   v-if="permission.trial_delete"
                   @click="handleExportExcel">导出
        </el-button>
        <el-button type="primary"
                    size="small"
                    plain
                    icon="el-icon-upload2"
                    @click="handleImport">导入
        </el-button>
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.flyOperator_delete"
                   @click="handleDelete">删 除
        </el-button>
        <el-button type="success"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.flyOperator_turn"
                   @click="handleMultiple(1)">批量启用
        </el-button>
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.flyOperator_turn"
                   @click="handleMultiple(0)">批量停用
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          icon="el-icon-setting"
          size="small"
          @click.stop="handleRowClick(scope.row)"
        >
          {{ scope.row.status === 1 ? '停用' : '启用' }}
        </el-button>
      </template>
    </avue-crud>
    <el-dialog title="用户数据导入"
                append-to-body
                :visible.sync="excelBox"
                width="555px">
      <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
        <template slot="excelTemplate">
          <el-button type="primary" @click="handleTemplate">
            点击下载<i class="el-icon-download el-icon--right"></i>
          </el-button>
        </template>
      </avue-form>
    </el-dialog>
    <DetailDialog v-if="dialogVisible" :dialogData="dialogData" :dialogVisible="dialogVisible" @closeDialog="closeDialog" />
  </basic-container>
</template>

<script>
  import {detail} from "@/api/phugiacloud/operatorTask";
  import {getList, getDetail, add, update, remove, turnOn, turnOff} from "@/api/phugiacloud/flyOperator";
  import { getUser } from "@/api/system/dept";
  import {mapGetters} from "vuex";
  import {getToken} from '@/util/auth';
  import {exportExcel} from '@/util/export'
  import DetailDialog from '@/components/task-detail-dialog'

  export default {
    name: 'fly-people-manager',
    components: {
      DetailDialog
    },
    data() {
      return {
        form: {},
        query: {},
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        dialogVisible: false,
        dialogData: {},
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          viewBtn: false,
          editBtn: false,
          selection: true,
          searchBtnText: "搜索",
          dialogClickModal: false,
          // excelBtn: true,
          uploadBtn: true,
          columnBtn: false,
          column: [
            {
              label: "姓名",
              prop: "realName",
              display: false,
              search: true
            },
            {
              label: "性别",
              prop: "sexName",
              display: false
            },
            {
              label: "所属团队",
              prop: "deptId",
              // search: true,
              display: false,
              type: "tree",
              dicUrl: "/api/blade-system/dept/tree",
              props: {
                label: "title"
              },
              change: ({value,column}) => {
                this.form.workNum = ''
                this.getUsers(value)
              },
              rules: [
                {
                  required: true,
                  message: "请选择所属团队",
                  trigger: "click"
                }
              ]
            },
            {
              label: "所属团队",
              prop: "teamId",
              hide: true,
              search: true,
              // search: true,
              type: "tree",
              dicUrl: "/api/blade-system/dept/tree",
              props: {
                label: "title"
              },
              change: ({value,column}) => {
                this.getUsers(value)
              },
              showColumn: false,
              rules: [
                {
                  required: true,
                  message: "请选择所属团队",
                  trigger: "click"
                }
              ]
            },
             {
              label: "执行任务",
              prop: "recentTask",
              display: false
            },
            {
              label: "使用设备",
              prop: "recentEquipment",
              display: false
            },
            {
              label: "手机",
              prop: "phone",
              search: true,
              display: false
            },
            {
              label: "飞手状态",
              prop: "accountStatusZH",
              display: false
            },
            // {
            //   label: "在线状态",
            //   prop: "status",
            //   addDisplay: false,
            //   viewDisplay: false,
            //   editDisplay: false
            // },
            {
              label: "最后登录",
              prop: "lastLoginTime",
              display: false
            },
            {
              label: "飞手姓名",
              hide: true,
              prop: "userId",
              type: "select",
              dicData: [],
              rules: [{
                required: true,
                message: "请选择飞手姓名",
                trigger: "blur"
              }],
              change: ({value,column}) => {
                if (column.dicData.length) {
                  let codeArr= column.dicData
                  codeArr.map(item => {
                    if (value === item.value) {
                      this.form.workNum = item.workNum
                    }
                  }) 
                }
              },
            },
            {
              label: "飞手工号",
              prop: "workNum",
              hide: true
            }
          ]
        },
        excelForm: {},
        excelOption: {
          submitBtn: false,
          emptyBtn: false,
          column: [
            {
              label: '模板上传',
              prop: 'excelFile',
              type: 'upload',
              drag: true,
              loadText: '模板上传中，请稍等',
              span: 24,
              propsHttp: {
                res: 'data'
              },
              tip: '请上传 .xls,.xlsx 标准格式文件',
              action: "/api/cloud-user/fly-operator/importData"
            },
            {
              label: '模板下载',
              prop: 'excelTemplate',
              formslot: true,
              span: 24,
            }
          ]
        },
        excelBox: false,
        data: []
      };
    },
    watch: {
      'excelForm.isCovered'() {
        if (this.excelForm.isCovered !== '') {
          const column = this.findObject(this.excelOption.column, "excelFile");
          column.action = `/api/blade-user/import-user?isCovered=${this.excelForm.isCovered}`;
        }
      }
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.flyOperator_add, false),
          viewBtn: this.vaildData(this.permission.flyOperator_view, false),
          delBtn: this.vaildData(this.permission.flyOperator_delete, false),
          editBtn: this.vaildData(this.permission.flyOperator_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    mounted() {},
    methods: {
      // 导出功能
      handleExportExcel() {
        const option = {
          title: "飞手信息",
          columns: [
             {
              label: "姓名",
              prop: "realName",
              display: false,
              search: true
            },
            {
              label: "性别",
              prop: "sexName",
              display: false
            },
            {
              label: "所属团队",
              prop: "$deptId"
            },
            {
              label: "执行任务",
              prop: "recentTask",
              display: false
            },
            {
              label: "使用设备",
              prop: "recentEquipment",
              display: false
            },
            {
              label: "手机",
              prop: "phone",
              search: true,
              display: false
            },
            {
              label: "飞手状态",
              prop: "accountStatusZH",
              display: false
            },
            {
              label: "最后登录",
              prop: "lastLoginTime",
              display: false
            }
          ]
        }
        const _this = this
        exportExcel(option, _this)
      },
      closeDialog(done) {
        this.dialogVisible = false
        done()
      },
      // 查看任务详情
      viewDetail(row, status) {
        const params = {
          id: row.taskId
        }
        detail(params).then(res => {
          if (res.data.code === 200) {
            this.dialogData = res.data.data
            this.dialogVisible = true
          }
        })
      },
      // 查看设备详情
      handleViewEquipment(row) {
        this.$router.push('/phugiacloud/equipmentManagement?equipId=' + row.equipmentId)
      },
      // 启用&停用
      handleRowClick(row) {
        const status = row.status
        const ids = row.id
        if (status === 1) {
          turnOff(ids).then(res => {
            this.$message.success(res.data.msg)
          }).then(() => {
            this.onLoad(this.page);
            this.$refs.crud.toggleSelection();
          }).catch(err => {
            this.$message.success(err.data.msg)
          })
        } else {
          turnOn(ids).then(res => {
            this.$message.success(res.data.msg)
          }).then(() => {
            this.onLoad(this.page);
            this.$refs.crud.toggleSelection();
          }).catch(err => {
            this.$message.success(err.data.msg)
          })
        }
      },
      // 批量停用启用
      handleMultiple(status) {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        let ids = this.ids
        if (status === 1) {
          this.$confirm("确定批量启用选中飞手?", {
          confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              return turnOn(ids);
            })
            .then(() => {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: "操作成功!"
              });
              this.$refs.crud.toggleSelection();
            });
        } else {
          this.$confirm("确定批量停用选中飞手?", {
          confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              return turnOff(ids);
            })
            .then(() => {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: "操作成功!"
              });
              this.$refs.crud.toggleSelection();
            });
        }
      },
      getUsers(value) {
        getUser({deptId: value}).then(res => {
          let dicData = []
          if (res.data.code === 200) {
            const data = res.data.data
            data.map(item => {
              dicData.push({
                label: item.realName,
                value: item.id,
                workNum: item.workNum
              })
            })
            this.$nextTick(() => {
              let column = this.findObject(this.option.column, "userId");
              column.dicData = JSON.parse(JSON.stringify(dicData))
            })
          }
        })
      },
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleImport() {
        this.excelBox = true;
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionChange(list) {
        this.selectionList = list;
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      handleTemplate() {
        window.open(`/api/cloud-user/fly-operator/download?${this.website.tokenHeader}=${getToken()}`);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.data.map(item => {
            item.accountStatusZH = parseInt(item.status) === 1 ? '正常' : '停用'
          })
          this.loading = false;
          this.selectionClear();
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
.viewDetail {
  color: #67C23A !important;
  cursor: pointer;
}
</style>
