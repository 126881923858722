var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "recentTask",
              fn: function (ref) {
                var row = ref.row
                return [
                  _c(
                    "span",
                    {
                      staticClass: "viewDetail",
                      on: {
                        click: function ($event) {
                          return _vm.viewDetail(row)
                        },
                      },
                    },
                    [_vm._v(_vm._s(row.recentTask))]
                  ),
                ]
              },
            },
            {
              key: "recentEquipment",
              fn: function (ref) {
                var row = ref.row
                return [
                  _c(
                    "span",
                    {
                      staticClass: "viewDetail",
                      on: {
                        click: function ($event) {
                          return _vm.handleViewEquipment(row)
                        },
                      },
                    },
                    [_vm._v(_vm._s(row.recentEquipment))]
                  ),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "el-icon-setting",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleRowClick(scope.row)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(scope.row.status === 1 ? "停用" : "启用") +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.permission.trial_delete
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-download",
                        plain: "",
                      },
                      on: { click: _vm.handleExportExcel },
                    },
                    [_vm._v("导出\n      ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    plain: "",
                    icon: "el-icon-upload2",
                  },
                  on: { click: _vm.handleImport },
                },
                [_vm._v("导入\n      ")]
              ),
              _vm.permission.flyOperator_delete
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                        plain: "",
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("删 除\n      ")]
                  )
                : _vm._e(),
              _vm.permission.flyOperator_turn
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "small",
                        icon: "el-icon-delete",
                        plain: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleMultiple(1)
                        },
                      },
                    },
                    [_vm._v("批量启用\n      ")]
                  )
                : _vm._e(),
              _vm.permission.flyOperator_turn
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                        plain: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleMultiple(0)
                        },
                      },
                    },
                    [_vm._v("批量停用\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户数据导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              attrs: {
                option: _vm.excelOption,
                "upload-after": _vm.uploadAfter,
              },
              model: {
                value: _vm.excelForm,
                callback: function ($$v) {
                  _vm.excelForm = $$v
                },
                expression: "excelForm",
              },
            },
            [
              _c(
                "template",
                { slot: "excelTemplate" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleTemplate },
                    },
                    [
                      _vm._v("\n          点击下载"),
                      _c("i", {
                        staticClass: "el-icon-download el-icon--right",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c("DetailDialog", {
            attrs: {
              dialogData: _vm.dialogData,
              dialogVisible: _vm.dialogVisible,
            },
            on: { closeDialog: _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }